var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Tạo link xem thêm",
        visible: _vm.dialogVisible,
        width: "40%",
        "before-close": _vm.closeDialog,
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "news",
          attrs: {
            "label-width": "120px",
            model: _vm.news,
            "label-position": "left",
            rules: _vm.rules,
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "Link mở rộng", prop: "link" } },
            [
              _c("el-input", {
                attrs: {
                  type: "text",
                  autosize: "",
                  placeholder: "Nhập link mở rộng",
                },
                model: {
                  value: _vm.news.link,
                  callback: function ($$v) {
                    _vm.$set(_vm.news, "link", $$v)
                  },
                  expression: "news.link",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Hiển thị cho", prop: "appTypeArr" } },
            [
              _c(
                "el-checkbox",
                {
                  attrs: { disabled: this.lengthAppPlusNews >= 1 },
                  model: {
                    value: _vm.news.appPlus,
                    callback: function ($$v) {
                      _vm.$set(_vm.news, "appPlus", $$v)
                    },
                    expression: "news.appPlus",
                  },
                },
                [_vm._v("App Plus")]
              ),
              _c(
                "el-checkbox",
                {
                  attrs: { disabled: this.lengthAppTeacherNews >= 1 },
                  model: {
                    value: _vm.news.appTeacher,
                    callback: function ($$v) {
                      _vm.$set(_vm.news, "appTeacher", $$v)
                    },
                    expression: "news.appTeacher",
                  },
                },
                [_vm._v("App Teacher")]
              ),
              _c(
                "el-checkbox",
                {
                  attrs: { disabled: this.lengthAppParentNews >= 1 },
                  model: {
                    value: _vm.news.appParent,
                    callback: function ($$v) {
                      _vm.$set(_vm.news, "appParent", $$v)
                    },
                    expression: "news.appParent",
                  },
                },
                [_vm._v("App OneKids")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "danger", size: "medium" },
              on: {
                click: function ($event) {
                  return _vm.closeDialogByButton("news")
                },
              },
            },
            [
              _c("i", { staticClass: "el-icon-circle-close" }),
              _c("span", [_vm._v("Đóng")]),
            ]
          ),
          _c(
            "el-button",
            {
              attrs: {
                type: "success",
                size: "medium",
                loading: _vm.loadingButton,
                mini: "",
              },
              on: {
                click: function ($event) {
                  return _vm.submitForm("news")
                },
              },
            },
            [
              _c("i", { staticClass: "el-icon-circle-check" }),
              _c("span", [_vm._v("Lưu")]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }